<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
    >
      <v-card>
        <v-card-title>
          หน้าหลัก <v-spacer></v-spacer> <v-btn
            color="success"
            @click="savemainpage"
          >
            Save
          </v-btn>
        </v-card-title>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Upload Logo
          </v-col>
          <v-col cols="2">
            <v-img
              v-if="mainpage.logo"
              width="105"
              height="25"
              :src="'https://cdn.nextlotto.co/' + mainpage.logo"
            ></v-img>
          </v-col>
          <v-col>
            <v-file-input
              v-model="logotmp"
              label="105 x 25"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              @click="uploadlogo"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Domain
          </v-col>
          <v-col>
            <v-text-field
              v-model="mainpage.domain"
              label="Domain เว็บ"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Title
          </v-col>
          <v-col>
            <v-text-field
              v-model="mainpage.title"
              label="ชื่อหัวเว็บ"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Description
          </v-col>
          <v-col>
            <v-textarea
              v-model="mainpage.description"
              label="คำอธิบายเว็บ"
              dense
              hide-details
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Keywords
          </v-col>
          <v-col>
            <v-combobox
              v-model="mainpage.keywords"
              multiple
              chips
              deletable-chips
              label="Keywords หน้าหลัก"
              hide-details
              outlined
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Entry
          </v-col>
          <v-col>
            <v-text-field
              v-model="mainpage.entry"
              label="Link ทางเข้า"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Register
          </v-col>
          <v-col>
            <v-text-field
              v-model="mainpage.register"
              label="Link สมัคร"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Line@
          </v-col>
          <v-col>
            <v-text-field
              v-model="mainpage.lineadd"
              label="Link Line@"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Banner
          </v-col>
          <v-col>
            <v-file-input
              v-model="bannertmp"
              label="635 x 300"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              @click="uploadbanner"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="mainpage.banner"
          class="ma-0 pb-1 px-2 justify-end align-center"
        >
          <v-col cols="10">
            <v-img
              width="635"
              height="300"
              :src="'https://cdn.nextlotto.co/' + mainpage.banner"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Slide 1
          </v-col>
          <v-col>
            <v-file-input
              v-model="slide1tmp"
              label="640 x 440"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              @click="uploadslide1"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="mainpage.slide1"
          class="ma-0 pb-1 px-2 justify-center align-center"
        >
          <v-col cols="1">
            <v-chip
              v-for="(item, index) in mainpage.slide1"
              :key="index"
              close
              class="mb-1"
              color="info"
              @click:close="removeslide1(index)"
            >
              {{ 'รูปที่ ' + (index + 1) }}
            </v-chip>
          </v-col>
          <v-col cols="9">
            <VueSlickCarousel
              class="pt-4 px-2"
              :arrows="false"
              :dots="false"
              autoplay
              :autoplay-speed="2000"
            >
              <v-img
                v-for="(item, index) in mainpage.slide1"
                :key="index"
                contain
                width="320px"
                height="220px"
                class="rounded"
                :src="'https://cdn.nextlotto.co/' + item"
                alt="cover1"
              >
              </v-img>
            </VueSlickCarousel>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Slide 2
          </v-col>
          <v-col>
            <v-file-input
              v-model="slide2tmp"
              label="450 x 450"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              @click="uploadslide2"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="mainpage.slide2"
          class="ma-0 pb-1 px-2 justify-center align-center"
        >
          <v-col cols="1">
            <v-chip
              v-for="(item, index) in mainpage.slide2"
              :key="index"
              close
              class="mb-1"
              color="info"
              @click:close="removeslide2(index)"
            >
              {{ 'รูปที่ ' + (index + 1) }}
            </v-chip>
          </v-col>
          <v-col cols="9">
            <VueSlickCarousel
              class="pt-4 px-2"
              :arrows="false"
              :dots="false"
              autoplay
              :autoplay-speed="2000"
            >
              <v-img
                v-for="(item, index) in mainpage.slide2"
                :key="index"
                contain
                width="320px"
                height="220px"
                class="rounded"
                :src="'https://cdn.nextlotto.co/' + item"
                alt="cover1"
              >
              </v-img>
            </VueSlickCarousel>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Content 1
          </v-col>
          <v-col>
            <tiptap-vuetify
              v-model="mainpage.content1"
              class="text-center"
              :extensions="extensions"
              :toolbar-attributes="{ color: 'info' }"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Slide 3
          </v-col>
          <v-col>
            <v-file-input
              v-model="slide3tmp"
              label="450 x 450"
              filled
              :prepend-icon="icons.mdiCamera"
              outlined
              hide-details
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="primary"
              @click="uploadslide3"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="mainpage.slide3"
          class="ma-0 pb-1 px-2 justify-center align-center"
        >
          <v-col cols="1">
            <v-chip
              v-for="(item, index) in mainpage.slide3"
              :key="index"
              close
              class="mb-1"
              color="info"
              @click:close="removeslide3(index)"
            >
              {{ 'รูปที่ ' + (index + 1) }}
            </v-chip>
          </v-col>
          <v-col cols="9">
            <VueSlickCarousel
              class="pt-4 px-2"
              :arrows="false"
              :dots="false"
              autoplay
              :autoplay-speed="2000"
            >
              <v-img
                v-for="(item, index) in mainpage.slide3"
                :key="index"
                contain
                width="320px"
                height="220px"
                class="rounded"
                :src="'https://cdn.nextlotto.co/' + item"
                alt="cover1"
              >
              </v-img>
            </VueSlickCarousel>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="2">
            Content 2
          </v-col>
          <v-col>
            <tiptap-vuetify
              v-model="mainpage.content2"
              class="text-center"
              :extensions="extensions"
              :toolbar-attributes="{ color: 'info' }"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-5 px-5 justify-end align-center">
          <v-btn
            color="success"
            @click="savemainpage"
          >
            Save
          </v-btn>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiCamera,
  mdiMagnify,
} from '@mdi/js'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Image,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import Gallery from '@/components/Gallery.vue'

export default {
  components: { VueSlickCarousel, TiptapVuetify },
  setup() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiCamera,
        mdiMagnify,
      },
    }
  },
  data() {
    return {
      logo: null,
      logotmp: null,
      bannertmp: null,
      slide1tmp: null,
      slide2tmp: null,
      slide3tmp: null,
      mainpage: {},
      extensions: [
        History,
        Blockquote,
        Link,
        [Image,
          {
            options: {
              imageSources: [
                { component: Gallery, name: 'Gallery' },
              ],
            },
          }],
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    }
  },
  async created() {
    const { data } = await this.axios.get('/admin/mainpage')
    this.mainpage = data.website
  },
  methods: {
    async savemainpage() {
      if (confirm('ยืนยันการบันทึกหน้าหลัก ?')) {
        await this.axios.put('/admin/mainpage', this.mainpage)
        window.location.reload()
      }
    },
    async uploadlogo() {
      if (this.logotmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.logotmp)
          await this.axios.post('/admin/logo', image)
          const { data: { website } } = await this.axios.get('/admin/mainpage')
          this.mainpage = website
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.logotmp = null
    },
    async uploadbanner() {
      if (this.bannertmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.bannertmp)
          await this.axios.post('/admin/banner', image)
          const { data: { website } } = await this.axios.get('/admin/mainpage')
          this.mainpage = website
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.bannertmp = null
    },
    async uploadslide1() {
      if (this.slide1tmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.slide1tmp)
          await this.axios.post('/admin/slide1', image)
          window.location.reload()
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.slide1tmp = null
    },
    async uploadslide2() {
      if (this.slide2tmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.slide2tmp)
          await this.axios.post('/admin/slide2', image)
          window.location.reload()
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.slide2tmp = null
    },
    async uploadslide3() {
      if (this.slide3tmp) {
        if (confirm('update img ?')) {
          const image = new FormData()
          image.append('img', this.slide3tmp)
          await this.axios.post('/admin/slide3', image)
          window.location.reload()
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
      this.slide3tmp = null
    },
    async removeslide1(index) {
      if (confirm('ลบรูปภาพ ?')) {
        await this.axios.delete('/admin/slide1', { params: { index } })
        window.location.reload()
      }
    },
    async removeslide2(index) {
      if (confirm('ลบรูปภาพ ?')) {
        await this.axios.delete('/admin/slide2', { params: { index } })
        window.location.reload()
      }
    },
    async removeslide3(index) {
      if (confirm('ลบรูปภาพ ?')) {
        await this.axios.delete('/admin/slide3', { params: { index } })
        window.location.reload()
      }
    },
  },
}
</script>
