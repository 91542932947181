<template>
  <v-row justify="center">
    <!-- <v-dialog
      v-model="imgdialog"
      :retain-focus="false"
      fullscreen
      persistent
      max-width="100%"
    > -->
    <v-card>
      <v-card-title>
        <span class="text-h5">คลังภาพ คลิกที่ภาพเพื่อเพิ่มใน Content</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="7"
          >
            <v-text-field
              v-model="tmpimgname"
              label="ชื่อภาพ"
              dense
              show-size
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            cols="5"
          >
            <v-file-input
              v-model="tmpimg"
              label="เลือกรูปภาพ"
              dense
              show-size
              hide-details
              :prepend-icon="icons.mdiCamera"
              outlined
            ></v-file-input>
          </v-col>
          <v-col
            cols="12"
          >
            <v-btn
              :disabled="isupload"
              color="primary"
              @click="uploadimg"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
        <v-progress-linear
          v-if="isupload"
          class="my-2"
          buffer-value="55"
          color="success"
          reverse
          stream
          value="30"
        ></v-progress-linear>
        <v-row>
          <v-col
            v-for="img in images"
            :key="img._id"
            class="d-flex child-flex"
            cols="4"
          >
            <v-img
              :src="img.url"
              aspect-ratio="1"
              class="grey lighten-2"
              @click="addtocontent(img)"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- </v-dialog> -->
  </v-row>
</template>

<script>
import {
  mdiCamera,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiCamera,
      },
    }
  },
  name: 'Gallery',
  data() {
    return {
      isupload: false,
      tmpimg: null,
      tmpimgname: '',
      images: [],
    }
  },
  created() {
    this.getimages()
  },
  methods: {
    addtocontent(img) {
      this.$emit('select-file', { src: img.url, alt: img.alt })
    },
    async getimages() {
      const { data: { images } } = await this.axios.get('/admin/images')
      this.images = images
    },
    async uploadimg() {
      if (this.tmpimg) {
        if (confirm('ยืนยันการ Upload')) {
          this.isupload = true
          const image = new FormData()
          image.append('img', this.tmpimg)
          image.append('alt', this.tmpimgname)
          await this.axios.post('/admin/image', image)
          this.isupload = false
          this.tmpimg = null
          this.tmpimgname = ''
          this.getimages()
        }
      } else {
        alert('กรุณาเลือกรูปภาพ')
      }
    },
  },
}
</script>
